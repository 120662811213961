import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import NCTO from "../pages/apps/NCTO/NCTO";
import { PageLayout } from "../components/Nav";
import { Loader } from "../components/general_fragments/Loaders";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


// const Reports = lazy(() => import("./pages/reports/Reports"));
const Home = lazy(() => import("../pages/Home"));
// import Reports from "./pages/reports/Reports";
// const FirmAR = lazy(() => import("./pages/reports/ar/FirmAR"));
// const DeptAR = lazy(() => import("./pages/reports/ar/DeptAR"));
// const RespAR = lazy(() => import("./pages/reports/ar/RespAR"));
// const Member = lazy(() => import("./pages/reports/powerbi/Member"));
const Morale = lazy(() => import("../pages/people/Morale/Morale"));
const Review = lazy(() => import("../pages/people/Review/Review"));
const Request = lazy(() => import("../pages/people/Request/Request"))
// const Bingo = lazy(() => import("./pages/apps/Bingo"));
// const NCTO = lazy(() => import("./pages/apps/NCTO/NCTO"))
const NCTOLandingPage = lazy(() => import("../pages/apps/NCTO/LandingPage"));
const Approval = lazy(() => import("../pages/apps/NCTO/Approval"));
const ApprovalListPage = lazy(() =>
    import("../pages/apps/NCTO/ApprovalListPage")
);
const BOI = lazy(() => import("../pages/apps/NCTO/BOI"));
const Reject = lazy(() => import('../pages/apps/NCTO/Reject'))

export default function ActiveUserRoute(props) {
    const version = "2.0.3"

    try {
        if (localStorage.getItem('version') !== version) {
            localStorage.clear()
            sessionStorage.clear()

            localStorage.setItem('version', version)
        }
    } catch {
        localStorage.clear()
        sessionStorage.clear()

        localStorage.setItem('version', version)
    }

    const authenticated = useSelector((state) => state.user.authenticated);
    // const user = useSelector((state) => state.user.STAFFINDEX);
    // const bearer = useSelector((state) => state.user.bearer);
    // const apiRoot = useSelector(state => state.globals.apiRoot)
    // const state = useSelector(state => state);

    if (authenticated === true) {
        // if (true === true) {
        // fetch(`${apiRoot}/api/tracking/login/${user}`, {
        //     method: "PUT",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         Authorization: bearer,
        //     },
        // })

        return (
            <Router>
                <PageLayout setShowHelp={props.setShowHelp}>
                    <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={
                                    <Home
                                        setGraph={props.setGraph}
                                        inProgress={props.inProgress}
                                    />
                                }
                            />
                            {/* <Route exact path="/reports" element={<Reports />} /> */}
                            {/* <Route exact path="/reports/ar/firm" element={<FirmAR />} /> */}
                            {/* <Route exact path="/reports/ar/dept" element={<DeptAR />} /> */}
                            {/* <Route
                exact
                path="/reports/ar/responsible"
                element={<RespAR />}
              /> */}

                            {/* <Route
                exact
                path="/reports/wip/responsible"
                element={<RespWIP />}
              /> */}
                            {/* <Route exact path="/reports/wip/self" element={<MyWIP />} /> */}
                            {/* <Route exact path="/reports/wip/dept" element={<DeptWIP />} /> */}
                            {/* <Route exact path="/reports/wip/team" element={<TeamWIP />} /> */}

                            {/* <Route
                exact
                path="/reports/powerbi/member"
                element={<Member />}
              /> */}
                            <Route exact path="/people/morale" element={<Morale />} />
                            <Route exact path="/people/reviews" element={<Review />} />
                            <Route exact path="/people/requests" element={<Request />} />
                            {/* <Route exact path="/apps/bingo" element={<Bingo />} />  */}

                            <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
                            <Route exact path="/apps/ncto/form" element={<NCTO />} />
                            <Route
                                exact
                                path="/apps/ncto/approve/:office"
                                element={<ApprovalListPage />}
                            />
                            <Route
                                path="/apps/ncto/approve/:office/:key"
                                element={<Approval />}
                            />
                            <Route
                                path='/apps/ncto/reject/:office/:key'
                                element={<Reject />}
                            />
                            <Route exact path="/apps/ncto/boi" element={<BOI />} />

                            {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
                        </Routes>
                    </Suspense>
                </PageLayout>
            </Router>
        );
    } else if (authenticated === false) {
        return (
            <section>
                <p>Uh oh! Looks like you are not a registered user with this company</p>
                <button onClick={() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                }}>Click here to reset and login again</button>
            </section>
        );
    } else {
        return <Loader loading={true} />;
    }
}
