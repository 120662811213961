import { isEqual } from 'lodash';
import { setUser } from "../redux/loginSlice";
import { callMsGraph } from "./graph";

import { InteractionStatus } from "@azure/msal-browser";

export function login_auth(apiRoot, staff_email, graphData, inProgress, instance, authenticated, bearer, dispatch){

    if (
    !graphData &&
    authenticated === "pending" &&
    inProgress === InteractionStatus.None
    ) {
        
        instance
            .handleRedirectPromise()
            .then((tokenResponse) => {
                if (tokenResponse) {
                    callMsGraph(tokenResponse.accessToken)
                        .then((response) => {
                            console.log("response is",response);
                        })
                        .then(() => {
                            let email = tokenResponse.account.username.toUpperCase();
                            console.log(email);
                            if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
                                email = "Tax_Member48@MagnifyFirm.com".toUpperCase();
                            }
                            email = email.split("@");
                            let addressSite = email[1].split(".");
                            const user = email[0];
                            const domain = addressSite[0];
                            const ext = addressSite[1];
                            // const api_path = `https://demo-mag.azurewebsites.net/api/staff/me/${user}/${domain}/${ext}`;
                            
                            fetch(`${apiRoot}/staff/me/${user}/${domain}/${ext}`, {
                            // fetch(`https://demo-mag.azurewebsites.net/api/staff/me/${user}/${domain}/${ext}`, {
                                method: "GET",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    "x-functions-key":
                                        "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                                    Authorization: bearer,
                                },
                            })
                                .then((res) => {
                                    console.log("res is",res);
                                    if (!res.ok) {
                                        throw new Error(
                                            "Uh oh! Looks like you are not a registered user with this company"
                                        );
                                    } else {
                                        return res.json();
                                    }
                                })
                                .then((data) => {
                                    dispatch(
                                        setUser({ authenticated: true, ...data, bearer: bearer })
                                    );
                    
                                })
                                .catch((err) => {
                                    console.error(err);
                                    dispatch(
                                        setUser({
                                            authenticated: false,
                                            message:
                                                "Uh oh! Looks like you are not a registered user with this company!",
                                        })
                                    );
                                });
                        });
            } else {
                throw new Error("No token response!");
            }
        })
        .catch((err) => {
            console.error(err);
        });
} else if (authenticated === true) {
    
    let email = staff_email.toUpperCase().split("@");
    let addressSite = email[1].split(".");
    const user = email[0];
    const domain = addressSite[0];
    const ext = addressSite[1];

    fetch(`${apiRoot}/staff/me/${user}/${domain}/${ext}`, {
    // fetch(`https://demo-mag.azurewebsites.net/api/staff/me/${user}/${domain}/${ext}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-functions-key":
                "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
            Authorization: bearer,
        },
    }).then(res => {
        return res.json()
    }).then(data => {        
        if (!isEqual(data.STAFF_EMAIL, staff_email)) {
            dispatch(
                setUser({ authenticated: true, ...data, bearer: bearer })
            );
        }

    })
    
}
}